@font-face {
  font-family: "RCI Regular";
  src: local("RCI Regular"), url(../fonts/RCI-Regular.otf) format("truetype");
}

@font-face {
  font-family: "RCI Bold";
  src: local("RCI Bold"), url(../fonts/RCI-Bold.otf) format("truetype");
}

@font-face {
  font-family: "RCI Black";
  src: local("RCI Black"), url(../fonts/RCI-Black.otf) format("truetype");
}

@font-face {
  font-family: "RCI Bk";
  src: local("RCI Bk"), url(../fonts/RCI-Bk.woff) format("woff");
}

@font-face {
  font-family: "RCI Book";
  src: local("RCI Book"), url(../fonts/RCI-Book.otf) format("truetype");
}

body {
  height: 100%;
  margin: 0;
  font-family: "RCI Book", -apple-system, "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: 4px !important;
  width: 26px !important;
  height: 26px !important;
  background-color: white !important;
  border-radius: 30px;
  z-index: 1;
}

.slick-next {
  right: 4px !important;
  width: 26px !important;
  height: 26px !important;
  background-color: white !important;
  border-radius: 30px;
  z-index: 1;
}

.slick-prev:before,
.slick-next:before {
  position: absolute;
  top: 1px;
  left: -3px;
  font-size: 32px !important;
  line-height: 28px !important;
  color: black !important;
}

::selection {
  background-color: #ff7900;
  color: white;
}

/* === Google Recaptcha === */
.grecaptcha-badge {
  visibility: hidden;
}
